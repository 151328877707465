<template>
    <page-title
        title="スケジュール一覧"
        icon="bi-calendar3"
    >
    </page-title>

    <section class="section">
        <div class="row g-2 justify-content-around mb-2">
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertShortageModal('確保済カメラマン不足（2日）', 'alert-shortage-danger')"
            >
                <small>不足</small>
                <div class="border border-dark alert-shortage-danger d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>

            <div
                class="col-md-2 text-center pointer"
                @click="openAlertShortageModal('確保済みカメラマン不足気味（2日）', 'alert-shortage-warning')"
            >
                <small>不足気味</small>
                <div class="border border-dark alert-shortage-warning d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>

            <div
                class="col-md-2 text-center pointer"
                @click="openAlertDoubleBookingModal"
            >
                <small>Wブッキング</small>
                <div class="border border-dark alert-double-bookings d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center me-5 pointer"
                @click="openAlertEquipmentModal"
            >
                <small>備品不足</small>
                <div class="border border-dark no-alert d-flex justify-content-center align-items-center">
                    <span class="fs-2">-</span>
                </div>
            </div>

            <div
                class="col-md-2 text-center pointer"
                @click="$router.push({name: 'AlertUndecidedSchedule'})"
            >
                <small>事前確認未</small>
                <div class="border border-dark alert-undecided d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertConfirmModal('日程確認未実施アラート', 'alert-confirm-first', alert_confirm_first)"
            >
                <small>日程確認未</small>
                <div class="border border-dark alert-confirm-first d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertConfirmModal('日程最終確認未実施アラート', 'alert-confirm-last', alert_confirm_last)"
            >
                <small>最終確認未</small>
                <div class="border border-dark alert-confirm-last d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertConfirmModal('予備日未定アラート', 'alert-spare-schedule', alert_spare_schedule)"
            >
                <small>予備日未定</small>
                <div class="border border-dark alert-spare-schedule d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertTantoModal('カメラマン未定アラート', 'alert-invitation', alert_invitation, '未定')"
            >
                <small><i class="bi bi-camera-fill"></i><i class="bi bi-person-fill"></i>未定</small>
                <div class="border border-dark no-alert d-flex justify-content-center align-items-center">
                    <span class="fs-2">-</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertTantoModal('カメラマン未連絡アラート', 'alert-contact', alert_contact, '田中太郎、鈴木一郎')"
            >
                <small><i class="bi bi-camera-fill"></i><i class="bi bi-person-fill"></i>未連絡</small>
                <div class="border border-dark alert-contact d-flex justify-content-center align-items-center">
                    <span class="fs-2">2</span>
                </div>
            </div>
            <div
                class="col-md-2 text-center pointer"
                @click="openAlertTantoModal('スケジュール未完了アラート', 'alert-status', alert_status, '田中太郎、鈴木一郎')"
            >
                <small>未完了</small>
                <div class="border border-dark alert-status d-flex justify-content-center align-items-center">
                    <span class="fs-2">1</span>
                </div>
            </div>
        </div>
    </section>

    <section class="section">
        <div class="row align-items-center mb-4">
            <div class="row col-md-18">
                <div class="col-md-7">
                    <select class="form-select">
                        <option>-- 全学校 --</option>
                        <option>北部小</option>
                        <option>東部小</option>
                        <option>西部小</option>
                        <option>南部中</option>
                    </select>
                </div>
                <div class="col-md-7">
                    <select class="form-select" v-model="type">
                        <option>カメラマン</option>
                        <option>学校担当</option>
                    </select>
                </div>
                <div class="col-md-7">
                    <select class="form-select" v-if="type === 'カメラマン'">
                        <option>-- 全員 --</option>
                        <option>片岡高志</option>
                        <option>奥村明生</option>
                        <option>江口悠斗</option>
                        <option>鈴木一郎</option>
                        <option>佐藤二郎</option>
                        <option>北島三郎</option>
                        <option>伊藤四郎</option>
                        <option>野口五郎</option>
                        <option>未定</option>
                    </select>
                    <select class="form-select" v-if="type === '学校担当'">
                        <option>-- 全員 --</option>
                        <option>片岡高志</option>
                        <option>奥村明生</option>
                        <option>江口悠斗</option>
                    </select>
                </div>
                <div class="col-md-3 text-center">
                    <button class="btn btn-outline-primary">表示</button>
                </div>
            </div>
            <div class="d-flex justify-content-around col-md-6">
                <div class="col-md text-end"><button class="btn btn-outline-primary btn-sm"><i class="bi bi-skip-backward-fill"></i></button></div>
                <div class="col-md text-center align-self-center">2022年 7月</div>
                <div class="col-md text-start"><button class="btn btn-outline-primary btn-sm"><i class="bi bi-skip-forward-fill"></i></button></div>
            </div>
        </div>

        <div class="d-flex">
            <div class="col bg-primary text-center border"><span class="text-white">月</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">火</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">水</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">木</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">金</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">土</span></div>
            <div class="col bg-primary text-center border"><span class="text-white">日</span></div>
        </div>
        <div class="d-flex" style="min-height:300px">
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">1</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2">
                            <i class="bi bi-camera-fill"></i> <small>12/12</small>
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">2</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark alert-status p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> (孝)(坂) 中央小 遠足
                    </div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">3</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border rounded border-1 border-dark bg-light p-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-align-start"></i> (坂)[フ] 南中 修学旅行
                    </div>
                </div>
            </div>
            <div class="col border border-info">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center alert-shortage-warning">
                        <div class="text-center fs-5 mx-2">4</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-align-center"></i> (坂)[フ] 南中 修学旅行
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-chat-dots"></i> 北部小 打ち合わせ
                    </div>
                    <div class="text-danger text-center" style="font-size:.8rem;"><i class="bi bi-exclamation-triangle"></i> 社用車不足(3/2)</div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">5</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-align-center"></i> (坂)[フ] 南中 修学旅行
                    </div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-info">6</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12(1)/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="text-end me-1">
                        <button class="btn btn-link btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style="font-size:.8rem;"><i class="bi bi-plus"></i>他3件...</button>
                    </div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-danger">7</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12(2)/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 西部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 北部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 中央小 運動会予備日
                    </div>
                </div>
            </div>
        </div>
        <div class="d-flex" style="min-height:300px">
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">8</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">9</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">10</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">11</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 南部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 北部小 運動会
                    </div>
                    <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                        <i class="bi bi-camera2"></i> 中央小 運動会
                    </div>
                    <div class="text-end me-1">
                        <button class="btn btn-link btn-sm" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" style="font-size:.8rem;"><i class="bi bi-plus"></i>他5件...</button>
                    </div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">12</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-info">13</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-danger">14</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
        <div class="d-flex" style="min-height:300px">
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">15</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">16</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">17</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">18</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">19</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-info">20</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-danger">21</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
        <div class="d-flex" style="min-height:300px">
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">22</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">23</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">24</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">25</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">26</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-info">27</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2 text-danger">28</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
        <div class="d-flex" style="min-height:300px">
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">29</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">30</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border">
                <div class="bg-light border-bottom">
                    <div class="d-flex align-items-center">
                        <div class="text-center fs-5 mx-2">31</div>
                        <div class="text-center ms-1 pointer shadow-sm" @click="openHolidayModal">
                            <i class="bi bi-person-dash"></i> <small>0</small>
                        </div>
                        <div class="text-center ms-2 pointer shadow-sm" @click="openPartnerModal">
                            <i class="bi bi-people-fill"></i> <small>2</small>
                        </div>
                        <div class="text-center ms-2"><i class="bi bi-camera-fill"></i> <small>12/12</small></div>
                    </div>
                </div>
                <div>
                </div>
            </div>
            <div class="col border bg-secondary"></div>
            <div class="col border bg-secondary"></div>
            <div class="col border bg-secondary"></div>
            <div class="col border bg-secondary"></div>
        </div>
    </section>

    <!-- 詳細バー -->
    <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header">
            <h5 id="offcanvasRightLabel">
                スケジュール詳細一覧
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 北部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 中央小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 南部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 北部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 中央小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 南部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 北部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 中央小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 南部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 北部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 中央小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 南部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 北部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 中央小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark bg-light p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-camera2"></i> 南部小 運動会
            </div>
            <div class="m-1 border border-1 rounded border-dark alert-contact p-1 my-1 pointer" style="font-size:.8rem;" @click="openModal">
                <i class="bi bi-umbrella-fill"></i> 東部小 運動会予備日
            </div>
        </div>
    </div>

    <!-- モーダル -->
    <div v-if="modal">
        <transition name="fade">
            <div v-if="modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    スケジュール詳細
                                </h4>
                                <button type="button" class="btn-close" @click="closeModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group col-md-4 mb-4">
                                    <select class="form-select" v-model="schedule_type">
                                        <option>撮影</option>
                                        <option>イベント撮影</option>
                                        <option>打ち合わせ</option>
                                        <option>予備日</option>
                                        <option>その他</option>
                                    </select>
                                </div>

                                <div class="row align-items-end mb-4">
                                    <div class="form-group col-md-5">
                                        <label v-if="schedule_type === 'イベント撮影'">イベント名</label>
                                        <label v-else>学校名</label>
                                        <form-select-search
                                            :options="schools"
                                            v-model="school"
                                        ></form-select-search>
                                    </div>
                                    <div class="form-group col-md-8">
                                        <label v-if="schedule_type === 'イベント撮影'">ジャンル</label>
                                        <label v-else>スケジュール名</label>
                                        <input type="text" class="form-control" v-if="free_input ==='yes'">
                                        <select class="form-select" v-else>
                                            <option>入学式</option>
                                            <option>運動会</option>
                                            <option>修学旅行</option>
                                            <option>定期演奏会</option>
                                            <option>遠足</option>
                                            <option>林間学校</option>
                                            <option>卒業式</option>
                                            <option>単発</option>
                                        </select>
                                    </div>
                                    <div class="form-group col-md-4">
                                        <div class="form-check form-control">
                                            <input
                                                class="form-check-input ms-1 me-1"
                                                type="checkbox"
                                                id="flexCheckDefault3"
                                                v-model="free_input"
                                                true-value="yes"
                                                false-value="no"
                                            >
                                            <label class="form-check-label" for="flexCheckDefault3">
                                                自由入力
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div class="row g-0 align-items-center mb-2">
                                    <div class="col-md-4">
                                        <input type="date" class="form-control">
                                    </div>

                                    <template v-if="showTimes">
                                        <template v-if="is_unfixed === 'yes'">
                                            <div class="col-md-4 ms-3">
                                                <select class="form-select">
                                                    <option>AM</option>
                                                    <option>PM</option>
                                                    <option>放課後</option>
                                                </select>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="col-md-4 ms-3">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" min="0" max="23" step="1">
                                                    <span class="input-group-text">:</span>
                                                    <input type="number" class="form-control" min="0" max="59" step="1">
                                                </div>
                                            </div>
                                            <div class="col-md-1 text-center">〜</div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control" min="0" max="23" step="1">
                                                    <span class="input-group-text">:</span>
                                                    <input type="number" class="form-control" min="0" max="59" step="1">
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-if="has_several_days === 'yes'">
                                        <div class="col-md-4 ms-3" v-if="is_all_day === 'no' && is_unfixed === 'no'">
                                            <div class="input-group">
                                                <input type="number" class="form-control" min="0" max="23" step="1">
                                                <span class="input-group-text">:</span>
                                                <input type="number" class="form-control" min="0" max="59" step="1">
                                            </div>
                                        </div>
                                        <div class="col-md-1 text-center">〜</div>
                                        <div class="col-md-4 me-3">
                                            <input type="date" class="form-control">
                                        </div>
                                        <div class="col-md-4" v-if="is_all_day === 'no' && is_unfixed === 'no'">
                                            <div class="input-group">
                                                <input type="number" class="form-control" min="0" max="23" step="1">
                                                <span class="input-group-text">:</span>
                                                <input type="number" class="form-control" min="0" max="59" step="1">
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <div class="row align-content-end justify-content-end mb-3">
                                    <div class="form-group col-md-3">
                                        <div class="form-check form-control">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input ms-1 me-1"
                                                    type="checkbox"
                                                    v-model="is_unfixed"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                未定
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <div class="form-check form-control">
                                            <label class="form-check-label">
                                                <input
                                                    class="form-check-input ms-1 me-1"
                                                    type="checkbox"
                                                    v-model="is_all_day"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                終日
                                            </label>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-3">
                                        <div class="form-control">
                                            <label>
                                                <input
                                                    class="form-check-input ms-2"
                                                    type="checkbox"
                                                    v-model="has_several_days"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                <span class="ms-1">複数日</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <template v-if="schedule_type !== '打ち合わせ' && schedule_type !== 'その他'">
                                    <div class="form-group col-md-4 mb-2">
                                        <label>雨天時対応</label>
                                        <select class="form-select" v-model="rainy">
                                            <option>雨天決行</option>
                                            <option>雨天延期</option>
                                            <option>雨天中止</option>
                                        </select>
                                    </div>
                                    <template v-if="rainy === '雨天延期'">
                                        <div class="row g-0 align-items-center mb-2">
                                            <div class="col-md-4">
                                                <input type="date" class="form-control">
                                            </div>

                                            <template v-if="rainyShowTimes">
                                                <template v-if="is_unfixed_rainy === 'yes'">
                                                    <div class="col-md-4 ms-3">
                                                        <select class="form-select">
                                                            <option>AM</option>
                                                            <option>PM</option>
                                                            <option>放課後</option>
                                                        </select>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="col-md-4 ms-3">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" min="0" max="23" step="1">
                                                            <span class="input-group-text">:</span>
                                                            <input type="number" class="form-control" min="0" max="59" step="1">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 text-center">〜</div>
                                                    <div class="col-md-4">
                                                        <div class="input-group">
                                                            <input type="number" class="form-control" min="0" max="23" step="1">
                                                            <span class="input-group-text">:</span>
                                                            <input type="number" class="form-control" min="0" max="59" step="1">
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="row align-content-end justify-content-end mb-4">
                                            <div class="form-group col-md-3">
                                                <div class="form-check form-control">
                                                    <label class="form-check-label">
                                                        <input
                                                            class="form-check-input ms-1 me-1"
                                                            type="checkbox"
                                                            v-model="is_unfixed_rainy"
                                                            true-value="yes"
                                                            false-value="no"
                                                        >
                                                        未定
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="form-group col-md-3">
                                                <div class="form-check form-control">
                                                    <label class="form-check-label">
                                                        <input
                                                            class="form-check-input ms-1 me-1"
                                                            type="checkbox"
                                                            v-model="is_all_day_rainy"
                                                            true-value="yes"
                                                            false-value="no"
                                                        >
                                                        終日
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                    <div class="row mt-5 mb-4">
                                        <div class="col-md-3 text-center">
                                            <label class="col-form-label title">日程確認</label>
                                        </div>
                                        <div class="col-md-4">
                                            <input type="date" class="form-control" v-model="checked_date">
                                        </div>
                                        <div class="col-md-2">
                                            <button class="btn btn-outline-primary" @click="checkToday">今日</button>
                                        </div>
                                    </div>
                                </template>
                                <div class="row mb-4">
                                    <div class="col-md-18">
                                        <div class="row mb-2">
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label title" v-if="schedule_type === 'その他'">社員</label>
                                                <label class="col-form-label title" v-else>カメラマン</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">名</span>
                                                </div>
                                            </div>
                                            <div class="col-md-5" v-if="schedule_type === 'その他'">
                                                <button class="btn btn-outline-primary">全員追加</button>
                                            </div>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"></div>
                                            <div class="col-md-6">
                                                <select class="form-select">
                                                    <option>未定</option>
                                                    <option>片岡高志</option>
                                                    <option>奥村明生</option>
                                                    <option>江口悠斗</option>
                                                    <option>鈴木一郎</option>
                                                    <option>佐藤二郎</option>
                                                    <option>北島三郎</option>
                                                    <option>伊藤四郎</option>
                                                    <option>野口五郎</option>
                                                </select>
                                            </div>
                                            <template v-if="schedule_type !== 'その他'">
                                                <div class="form-group col-md-6">
                                                    <div class="form-control">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" value="">
                                                            カメラマン連絡済
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <div class="form-control">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" value="">
                                                            アラート無視
                                                        </label>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                        <div class="row mb-2">
                                            <div class="col-md-4"></div>
                                            <div class="col-md-6">
                                                <select class="form-select">
                                                    <option>未定</option>
                                                    <option>片岡高志</option>
                                                    <option>奥村明生</option>
                                                    <option>江口悠斗</option>
                                                    <option>鈴木一郎</option>
                                                    <option>佐藤二郎</option>
                                                    <option>北島三郎</option>
                                                    <option>伊藤四郎</option>
                                                    <option>野口五郎</option>
                                                </select>
                                            </div>
                                            <template v-if="schedule_type !== 'その他'">
                                                <div class="form-group col-md-6">
                                                    <div class="form-control">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" value="">
                                                            カメラマン連絡済
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-md-6">
                                                    <div class="form-control">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" value="">
                                                            アラート無視
                                                        </label>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-md-6 bg-light" v-if="free_input === 'no'">
                                        <div class="col-md">
                                            <div class="border-bottom">
                                                <div>学校担当:吉岡孝二</div>
                                                <div>販売：あり  集合写真：買取販売</div>
                                            </div>
                                            <div>
                                                <div>2021.04.14：坂野</div>
                                                <div>2020.04.13：坂野</div>
                                                <div>2019.04.11：坂野</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center mb-4">
                                    <div class="col-md-3 text-center">
                                        <label class="col-form-label title">社用車</label>
                                    </div>
                                    <div class="col">
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox1" value="option1">
                                            <label class="form-check-label" for="inlineCheckbox1">フ</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox2" value="option2">
                                            <label class="form-check-label" for="inlineCheckbox2">タ</label>
                                        </div>
                                        <div class="form-check form-check-inline">
                                            <input class="form-check-input" type="checkbox" id="inlineCheckbox3" value="option3">
                                            <label class="form-check-label" for="inlineCheckbox3">キャ</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center mb-4">
                                    <div class="col-md-3 text-center">
                                        <label class="col-form-label title">移動手段</label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="text" class="form-control">
                                    </div>
                                </div>
                                <div v-if="drop_down" class="mb-4">
                                    <div class="mb-4">
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center">
                                                <label class="col-form-label title">備品</label>
                                            </div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(小1段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(3段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(大1段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(板)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">枚</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ひな壇(2段)</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">基</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row mb-1">
                                            <div class="col-md-3 text-center"></div>
                                            <div class="col-md-4 text-center">
                                                <label class="col-form-label">ストロボ</label>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="input-group">
                                                    <input type="number" class="form-control">
                                                    <span class="input-group-text">セット</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row justify-content-between">
                                        <div class="col">
                                            <div class="mb-2">
                                                <input type="text" placeholder="場所" class="form-control">
                                            </div>
                                            <div class="row mb-2">
                                                <div class="col-md">
                                                    <input type="text" placeholder="先方窓口" class="form-control">
                                                </div>
                                                <div class="col-md">
                                                    <input type="tel" placeholder="連絡先電話番号" class="form-control">
                                                </div>
                                            </div>
                                            <div>
                                                <textarea class="form-control" rows="5" placeholder="メモ"></textarea>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <div class="mb-3">
                                                <button class="btn btn-outline-dark">ファイルを選択...</button>
                                            </div>
                                            <div class="mb-1"><i class="bi bi-file-earmark-image"></i> プログラム</div>
                                            <div class="mb-1"><i class="bi bi-file-earmark-image"></i> 配置図</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-end">
                                    <button class="btn btn-outline-primary" @click="toggleDrop">
                                        <i class="bi" :class="icon"></i> {{ drop_down_text }}
                                    </button>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <ul class="list-group col mx-5">
                                    <li class="list-group-item">
                                        <div>変更メモ</div>
                                        <div class="text-end text-secondary">
                                            2022.07.05 鈴木一郎
                                        </div>
                                    </li>
                                    <li class="list-group-item">
                                        <div class="d-flex align-items-end">
                                            <textarea class="form-control" rows="3" placeholder="先方とのやりとりを記述する"></textarea>
                                            <button class="btn btn-outline-primary nowrap ms-3" @click="saveNote()">登録</button>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <div v-if="schedule_type === '撮影'">
                                    <button type="button" class="btn btn-outline-success me-4" @click="$refs.confirm_finish.show()">
                                        <i class="bi bi-check-circle-fill"></i> 撮影完了...
                                    </button>
                                    <button type="button" class="btn btn-outline-warning me-4" @click="$refs.confirm_postpone.show()">
                                        <i class="bi bi-exclamation-triangle-fill"></i> 延期...
                                    </button>
                                    <button type="button" class="btn btn-outline-danger" @click="$refs.confirm_cancel.show()">
                                        <i class="bi bi-heartbreak"></i> 中止...
                                    </button>
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary me-5" @click="closeModal">
                                        <i class="bi bi-pencil-square"></i> 保存
                                    </button>
                                    <button type="button" class="btn btn-outline-danger" @click="closeModal">
                                        <i class="bi bi-trash"></i> 削除
                                    </button>
                                </div>
                            </div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 休暇管理モーダル -->
    <div v-if="holiday_modal">
        <transition name="fade">
            <div v-if="holiday_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    7/1 休み（0名）
                                </h4>
                                <button type="button" class="btn-close" @click="closeHolidayModal"></button>
                            </div>
                            <div class="modal-body">
                                <template v-for="employee in employees" :key="employee">
                                    <div class="row align-items-center mb-2">
                                        <div class="col-md-4">
                                            {{ employee.name }}
                                        </div>
                                        <div class="col-md-4">
                                            <label class="form-control">
                                                <input
                                                    v-model="employee.is_holiday"
                                                    type="checkbox"
                                                    class="form-check-input me-1"
                                                    true-value="yes"
                                                    false-value="no"
                                                >
                                                    休み
                                            </label>
                                        </div>
                                        <div class="col-md-4">
                                            <select class="form-select" v-if="employee.is_holiday === 'yes'">
                                                <option>公休</option>
                                                <option>有休</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12">
                                            {{ employee.event }}
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="closeHolidayModal">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 応援カメラマン管理モーダル -->
    <div v-if="partner_modal">
        <transition name="fade">
            <div v-if="partner_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title">
                                    7/1 応援カメラマンさん (1名)
                                </h4>
                                <button type="button" class="btn-close" @click="closePartnerModal"></button>
                            </div>
                            <div class="modal-body">
                                <template v-for="partner in partners" :key="partner">
                                    <div class="row align-items-center mb-4">
                                        <div class="col-md-5">
                                            {{ partner.name }} <small class="badge bg-secondary">{{ partner.cancel_number }}</small>
                                        </div>
                                        <div class="col-md-5">
                                            <select class="form-select" v-model="partner.request_status">
                                                <option>-- 未確認 --</option>
                                                <option>依頼済</option>
                                                <option>空き</option>
                                                <option>不可</option>
                                                <option>キャンセル</option>
                                            </select>
                                        </div>
                                        <div class="col-md-12">
                                            {{ partner.event }}
                                        </div>
                                    </div>
                                </template>
                                <hr>
                                <div class="row align-items-center mb-4">
                                    <div class="col-md-5">
                                        <select class="form-select">
                                            <option>- 選択 -</option>
                                            <option>鈴木一郎</option>
                                            <option>佐藤二郎</option>
                                            <option>北島三郎</option>
                                            <option>伊藤四郎</option>
                                        </select>
                                    </div>
                                    <div class="col-md-5">
                                        <select class="form-select">
                                            <option>依頼済</option>
                                            <option>空き</option>
                                            <option>不可</option>
                                            <option>キャンセル</option>
                                        </select>
                                    </div>
                                    <div class="col-md-12">
                                        <button class="btn btn-outline-primary" @click="addPartner()">
                                            <i class="bi bi-plus-lg"></i> 追加
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer d-flex justify-content-between">
                                <button type="button" class="btn btn-primary" @click="closePartnerModal">
                                    <i class="bi bi-pencil-square"></i> 保存
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 不足アラートモーダル -->
    <div v-if="alert_shortage_modal">
        <transition name="fade">
            <div v-if="alert_shortage_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div  class="text-center" :class="alert_css" style="width:50px; height:50px;">
                                        <h1><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <h4 class="modal-title ms-3">
                                        {{ alert_title }}
                                    </h4>
                                </div>
                                <button type="button" class="btn-close" @click="closeAlertShortageModal"></button>
                            </div>
                            <div class="modal-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="text-center">日付</th>
                                            <th class="text-center">必要カメラマン数</th>
                                            <th class="text-center">確保済カメラマン数</th>
                                            <th class="text-center">過不足</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">2022.07.01</td>
                                            <td class="text-center">39</td>
                                            <td class="text-center">37</td>
                                            <td class="text-center">-2</td>
                                        </tr>
                                        <tr>
                                            <td class="text-center">2022.07.05</td>
                                            <td class="text-center">39</td>
                                            <td class="text-center">37</td>
                                            <td class="text-center">-2</td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- ダブルブッキングアラートモーダル -->
    <div v-if="alert_double_booking_modal">
        <transition name="fade">
            <div v-if="alert_double_booking_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-lg">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div class="text-center alert-double-bookings" style="width:50px; height:50px;">
                                        <h1><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <h4 class="modal-title ms-3">
                                        ダブルブッキング
                                    </h4>
                                </div>
                                <button type="button" class="btn-close" @click="closeAlertDoubleBookingModal"></button>
                            </div>
                            <div class="modal-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-md-5 text-center">日付</th>
                                            <th class="col-md-5 text-center">カメラマン名</th>
                                            <th class="col-md-18 text-center">ブッキングスケジュール</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">2022.07.01</td>
                                            <td class="text-center">鈴木一郎</td>
                                            <td>
                                                <div class="row align-items-center my-2">
                                                    <div class="col-md-10">
                                                            北部小 運動会
                                                    </div>
                                                    <div class="col-md-9">9:00 ~ 12:00</div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-outline-primary btn-sm" @click="openModal(); alert_double_booking_modal = false">
                                                            表示
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center">
                                                    <div class="col-md-10">
                                                            南部中 運動会
                                                    </div>
                                                    <div class="col-md-9">終日</div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-outline-primary btn-sm" @click="openModal(); alert_double_booking_modal = false">
                                                            表示
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!-- 備品不足アラートモーダル -->
    <div v-if="alert_equipment_modal">
        <transition name="fade">
            <div v-if="alert_equipment_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div class="text-center alert-equipments" style="width:50px; height:50px;">
                                        <h1><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <h4 class="modal-title ms-3">
                                        備品不足
                                    </h4>
                                </div>
                                <button type="button" class="btn-close" @click="closeAlertEquipmentModal"></button>
                            </div>
                            <div class="modal-body">
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th class="col-md-5 text-center">日付</th>
                                            <th class="col-md-5 text-center">備品名</th>
                                            <th class="col-md-5 text-center">所持数</th>
                                            <th class="col-md-18 text-center">使用詳細</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td class="text-center">2022.07.01</td>
                                            <td class="text-center">ひな壇（2段）</td>
                                            <td class="text-center">3</td>
                                            <td>
                                                <div class="row align-items-center my-2">
                                                    <div class="col-md-15">
                                                            北部小 運動会
                                                    </div>
                                                    <div class="col-md-4">2</div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-outline-primary btn-sm" @click="openModal(); alert_equipment_modal = false">
                                                            表示
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center my-2">
                                                    <div class="col-md-15">
                                                            南部中 運動会
                                                    </div>
                                                    <div class="col-md-4">1</div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-outline-primary btn-sm" @click="openModal(); alert_equipment_modal = false">
                                                            表示
                                                        </button>
                                                    </div>
                                                </div>
                                                <div class="row align-items-center my-2">
                                                    <div class="col-md-15">
                                                            東部小 アルバム撮影
                                                    </div>
                                                    <div class="col-md-4">1</div>
                                                    <div class="col-md-5">
                                                        <button class="btn btn-outline-primary btn-sm" @click="openModal(); alert_equipment_modal = false">
                                                            表示
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <!--予定アラートモーダル 事前確認未〜予備日未定まで -->
    <div v-if="alert_confirm_modal">
        <transition name="fade">
            <div v-if="alert_confirm_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div class="text-center" :class="alert_css" style="width:50px; height:50px;">
                                        <h1><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <h4 class="modal-title ms-3">
                                        {{ alert_title }}
                                    </h4>
                                </div>
                                <button type="button" class="btn-close" @click="closeAlertConfirmModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="alert alert-danger" role="alert">
                                    <i class="bi bi-exclamation-triangle"></i>{{ alert_schedule_content }}
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>スケジュール名</th>
                                            <th>学校担当者名</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>北部小 運動会</td>
                                            <td>田中太郎</td>
                                            <td>
                                                <button class="btn btn-outline-primary" @click="openModal(); alert_confirm_modal = false">
                                                    表示
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>南部中 修学旅行</td>
                                            <td>田中太郎</td>
                                            <td>
                                                <button class="btn btn-outline-primary" @click="openModal(); alert_confirm_modal = false">
                                                    表示
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
    <!--予定アラートモーダル カメラマン未定〜未完了まで -->
    <div v-if="alert_tanto_modal">
        <transition name="fade">
            <div v-if="alert_tanto_modal">
                <div class="modal-backdrop show"></div>
                <div class="modal" tabindex="-1" style="display:block">
                    <div class="modal-dialog modal-xl">
                        <div class="modal-content">
                            <div class="modal-header">
                                <div class="d-flex align-items-center">
                                    <div class="text-center" :class="alert_css" style="width:50px; height:50px;">
                                        <h1><i class="bi bi-exclamation-lg"></i></h1>
                                    </div>
                                    <h4 class="modal-title ms-3">
                                        {{ alert_title }}
                                    </h4>
                                </div>
                                <button type="button" class="btn-close" @click="closeAlertTantoModal"></button>
                            </div>
                            <div class="modal-body">
                                <div class="alert alert-danger" role="alert">
                                    <i class="bi bi-exclamation-triangle"></i>{{ alert_schedule_content }}
                                </div>
                                <table class="table">
                                    <thead>
                                        <tr>
                                            <th>スケジュール名</th>
                                            <th>カメラマン名</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>北部小 運動会</td>
                                            <td>{{ alert_tanto_name }}</td>
                                            <td>
                                                <button class="btn btn-outline-primary" @click="openModal(); alert_tanto_modal = false">
                                                    表示
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>南部中 修学旅行</td>
                                            <td>{{ alert_tanto_name }}</td>
                                            <td>
                                                <button class="btn btn-outline-primary" @click="openModal(); alert_tanto_modal = false">
                                                    表示
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot></tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

    <confirm-dialog ref="confirm_finish" @ok="closeModal()">
        <p>このスケジュールを撮影完了にしてよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_postpone" @ok="closeModal()">
        <p>このスケジュールを延期してよろしいですか？</p>
    </confirm-dialog>
    <confirm-dialog ref="confirm_cancel" @ok="closeModal()">
        <p>このスケジュールをキャンセルしてよろしいですか？</p>
    </confirm-dialog>

</template>

<script>
import PageTitle from '@/components/PageTitle';
import FormSelectSearch from '@/components/forms/FormSelectSearch';
import ConfirmDialog from '@/components/tools/ConfirmDialog';


export default {
    name: 'Schedules',
    components: {
        PageTitle,
        ConfirmDialog,
        FormSelectSearch
    },
    data() {
        return {
            type: 'カメラマン',
            schedule_type: '撮影',
            free_input: 'no',
            is_all_day: 'no',
            has_several_days: 'no',
            is_unfixed: 'no',
            is_all_day_rainy: 'no',
            is_unfixed_rainy: 'no',
            rainy:'雨天延期',
            checked_date: null,
            school: '北部小',
            schools: [
                {key:'1', label:'北部小'},
                {key:'2', label:'東部小'},
                {key:'3', label:'中央小'},
                {key:'4', label:'西部小'},
                {key:'5', label:'南部中'},
            ],
            partners: [
                {name: '野口五郎', cancel_number: 1,request_status: '依頼済', event:'北部小 運動会'},
                {name: '岡島六郎', cancel_number: 0,request_status: '不可', event:'予定なし'},
                {name: '七瀬みゆき', cancel_number: 3,request_status: '不可', event:'予定なし'},
            ],
            employees: [
                {name: '片岡高志', is_holiday: 'no', event:'北部小 運動会'},
                {name: '奥村明生', is_holiday: 'yes', event:''},
                {name: '江口悠斗', is_holiday: 'no', event:'予定なし'},
            ],
            icon: 'bi-chevron-down',
            drop_down: false,
            drop_down_text: '詳細情報を表示',

            modal: false,
            holiday_modal: false,
            partner_modal: false,
            alert_shortage_modal: false,
            alert_title: null,
            alert_css: null,
            alert_double_booking_modal: false,
            alert_equipment_modal: false,
            alert_confirm_modal: false,
            alert_tanto_modal: false,
            alert_schedule_content: null,
            alert_tanto_name: null,
            modal_title: '',
            alert_confirm_first: '予定日の90日前ですが、「日程確認日」が 90日以上前です。日程を学校に確認してください',
            alert_confirm_last: '予定日の30日前ですが、「日程確認日」が 30日以上前です。日程を学校に最終確認してください',
            alert_spare_schedule: '予定日30日前ですが、予備日が未定です。確認してください',
            alert_invitation: '予定日15日前ですが、カメラマンが未選択です。カメラマンを選択してください',
            alert_contact: '予定日5日前ですが、カメラマンに連絡済になっていません。カメラマンに連絡してください',
            alert_status: '予定日を過ぎましたが、ステータスが更新されていません。スケジュールを確認してください',
        }
    },
    computed: {
        showTimes() {
            if (this.is_all_day === 'yes') {
                return false;

            } else {
                if (this.has_several_days === 'yes') {
                    return false;
                }
            }

            return true;
        },
        rainyShowTimes() {
            if (this.is_all_day_rainy === 'yes') {
                return false;
            }

            return true;
        }
    },

    methods: {
        openPartnerModal() {
            this.partner_modal = true;

        },
        closePartnerModal() {
            this.partner_modal = false;
        },
        openHolidayModal() {
            this.holiday_modal = true;

        },
        closeHolidayModal() {
            this.holiday_modal = false;
        },
        openModal() {
            this.modal = true;

        },
        closeModal() {
            this.modal = false;
        },
        openAlertShortageModal(title, css) {
            this.alert_shortage_modal = true;
            this.alert_title = title;
            this.alert_css = css;
        },
        closeAlertShortageModal() {
            this.alert_shortage_modal = false;
            this.alert_title = null;
            this.alert_css = null;
        },
        openAlertDoubleBookingModal() {
            this.alert_double_booking_modal = true;

        },
        closeAlertDoubleBookingModal() {
            this.alert_double_booking_modal = false;
        },
        openAlertEquipmentModal() {
            this.alert_equipment_modal = true;

        },
        closeAlertEquipmentModal() {
            this.alert_equipment_modal = false;
        },
        openAlertConfirmModal(title, css, content) {
            this.alert_confirm_modal = true;
            this.alert_title = title;
            this.alert_css = css;
            this.alert_schedule_content = content;
        },
        closeAlertConfirmModal() {
            this.alert_confirm_modal = false;
            this.alert_title = null;
            this.alert_css = null;
            this.alert_schedule_content = null;
        },
        openAlertTantoModal(title, css, content, tanto) {
            this.alert_tanto_modal = true;
            this.alert_title = title;
            this.alert_css = css;
            this.alert_schedule_content = content;
            this.alert_tanto_name = tanto;
        },
        closeAlertTantoModal() {
            this.alert_tanto_modal = false;
            this.alert_title = null;
            this.alert_css = null;
            this.alert_schedule_content = null;
            this.alert_tanto_name = null;
        },
        addPartner() {
            this.partners.push({name: '蜂村太郎', request_status: '依頼済', event: '予定なし'});
        },
        //一括操作の出し入れ
        toggleDrop() {
            if (this.drop_down) {
                this.drop_down = false;
                this.icon = 'bi-chevron-down';
                this.drop_down_text = '詳細情報を表示';
            } else {
                this.drop_down = true;
                this.icon = 'bi-chevron-up'
                this.drop_down_text = '詳細情報を隠す';
            }
        },
        checkToday() {
            let dt = new Date();
            let y = dt.getFullYear();
            let m = ("00" + (dt.getMonth()+1)).slice(-2);
            let d = ("00" + dt.getDate()).slice(-2);
            let result = y + "-" + m + "-" + d;

            this.checked_date = result;
        }
    }
}
</script>

<style scoped>
.alert-shortage-danger {background: #f50000; color: #ffffff;}
.alert-shortage-warning {background: #f5f500; color: #000000}
.alert-double-bookings {background: #7bf500; color: #ffffff}
.alert-equipments {background: #f57b00; color: #ffffff}
.alert-undecided {background: #00f500; color: #ffffff}
.alert-confirm-first {background: #00f57b; color: #ffffff}
.alert-confirm-last {background: #00f5f5; color: #ffffff}
.alert-spare-schedule {background: #007bf5; color: #ffffff}
.alert-invitation {background: #0000f5; color: #ffffff}
.alert-contact {background: #7b00f5; color: #ffffff}
.alert-status {background: #f500f5; color: #ffffff}
.no-alert {background: #e6e1e3; color: #ffffff;}
.title {font-weight: bold;}
.pointer {cursor: pointer;}
</style>
